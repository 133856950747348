.ticker-wrapper {
  display: flex;
  overflow: hidden;
  width: 100%;
  position: relative;
}

.ticker-content {
  display: flex;
  gap: 10px;
  animation: scroll 40s linear infinite;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
